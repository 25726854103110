import React, { useState } from 'react'
import logo from '../../assets/GooglePlayStore.png'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useAuth } from '../../middleware/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { server } from '../../server';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res = await axios.post(`${server}/admins/login`, {
            email,
            password,
          });
          if (res && res.data.success) {
            toast.success("Login Success")
            setAuth({
              ...auth,
              admin: res.data.admin,
              token: res.data.token,
            });
            localStorage.setItem("auth", JSON.stringify(res.data));
            // navigate(location.state || "/home");
            setTimeout(() => {
                navigate(location.state || "/admin");
            }, 3000);
          } else {
            toast.error(res.data.message);
          }
        } catch (error) {
          toast.error("Invalid credentials provided. Please try again");
        }
      };
  return (
    <div>
        <section class="bg-gray-50">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a href="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
          {/* <img class="w-8 h-8 mr-2" src={logo} alt="logo"/> */}
          CryptoWell    
      </a>
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                  Welcome back Admin,
              </h1>
              <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                      <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="admin@company.com" required=""/>
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                      <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""/>
                  </div>
                  <div class="flex items-center justify-between">
                      <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required=""/>
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="remember" class="text-gray-500">Remember me</label>
                          </div>
                      </div>
                      {/* <a href="/" class="text-sm font-medium text-primary-600 hover:underline">Forgot password?</a> */}
                  </div>
                  <button type="submit" class="w-full text-white bg-orange-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign in</button>
                  {/* <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet? <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
                  </p> */}
              </form>
          </div>
      </div>
  </div>
</section>
    </div>
  )
}

export default AdminLogin