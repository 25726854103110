import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { useRef } from 'react';
import Login from './pages/Login';
import UserDashboard from './pages/UserDashboard';
import Investments from './pages/client/Investments';
import Withdrawals from './pages/client/Withdrawals';
import PrivateRoute from './middleware/ProtectedRoute';
import ConfirmInvestment from './pages/client/ConfirmInvestment';
import ConfirmWithdrawal from './pages/client/ConfirmWithdrawal';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminRoute from './middleware/AdminRoute';
import AdminLogin from './pages/Admin/AdminLogin';
import Users from './pages/Admin/Users';
import AdminInvestments from './pages/Admin/AdminInvestments';
import PendingInvestments from './pages/Admin/PendingInvestments';
import AdminPlans from './pages/Admin/AdminPlans';
import AddPlan from './pages/Admin/AddPlan';
import Register from './pages/Register';
import Profile from './pages/client/Profile';


function App() {
  const tawkMessengerRef = useRef();

  // const handleMinimize = () => {
  //   tawkMessengerRef.current.minimize();
  // };
  return (
    <>

      <TawkMessengerReact
        propertyId="6654c95e981b6c5647752cb6"
        widgetId="1hutj56e6"
        ref={tawkMessengerRef} />
      <BrowserRouter>

        {/* <ToastContainer /> */}
        <Routes>
          <Route path='/' element={
            <Home />
          } />
          <Route path='/login' element={
            <Login />
          } />
          <Route path='/signup' element={
            <Register />
          } />
          <Route element={<PrivateRoute />}>
            {/* <Route path='/home' element={<HomePage />} /> */}
            <Route path='/dashboard' element={
              <UserDashboard />
            } />
            <Route path='/invest' element={
              <Investments />
            } />
            <Route path='/confirm-investment' element={
              <ConfirmInvestment />
            } />
            <Route path='/confirm-withdrawal' element={
              <ConfirmWithdrawal />
            } />
            <Route path='/withdraw' element={
              <Withdrawals />
            } />
            <Route path='/profile' element={
              <Profile />
            } />

          </Route>

          <Route path='/admin/login' element={<AdminLogin />} />
          <Route element={<AdminRoute />}>
            <Route
              path="/admin/*"
              element={
                // <Layout>
                <Routes>
                  <Route path='/' element={<AdminDashboard />} />
                  <Route path='/users' element={<Users />} />
                  <Route path='/investments' element={<AdminInvestments />} />
                  <Route path='/plans' element={<AdminPlans />} />
                  <Route path='/add-plan' element={<AddPlan />} />
                  <Route path='/pending-investments' element={<PendingInvestments />} />
                </Routes>
                // </Layout>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
