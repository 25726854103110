import React, { useEffect, useState } from 'react'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import { FaArrowCircleRight, } from 'react-icons/fa'
import profile from '../../assets/rsprint.png'
import { useAuth } from '../../middleware/AuthContext';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';

const calculateTotalInvestedAmount = (investments) => {
  return investments.reduce((total, investment) => total + investment.amount, 0);
};

const AdminDashboard = () => {
  const [date, setDate] = useState(new Date());
  const [auth, setAuth] = useAuth();
  const [plans, setPlans] = useState([])
  const [investments, setInvestments] = useState([])
  const [users, setUsers] = useState([])
  const admin = auth.admin;
  const [recentInvestments, setRecentInvestments] = useState([]);
  const [selectedInvestment, setSelectedInvesment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);

  const totalInvestedAmount = calculateTotalInvestedAmount(investments);

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(`${server}/investments/unapproved-investments`);
        setRecentInvestments(response.data);
      } catch (error) {

      }
    };

    fetchSuppliers();
  }, []);

  const handleApprove = async () => {
    try {
      const response = await axios.patch(`${server}/investments/approve-investment/${selectedInvestment._id}`);
      toast.success(response.data);
      // Refresh the reseller list after approval
      const updatedInvestments = recentInvestments.filter(investment => investment._id !== selectedInvestment._id);
      setRecentInvestments(updatedInvestments);
      setIsModalOpen(false)
    } catch (error) {
      toast.error('Failed to approve supplier');
    }
  };

  const toggleModal = (investment) => {
    setIsModalOpen(!isModalOpen);
    setSelectedInvesment(investment)
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/plans/get-plans`);
        setPlans(response.data);

      } catch (error) {

      }
    };
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(`${server}/users/get-users`);
        setUsers(response.data);

      } catch (error) {

      }
    };
    const fetchResellers = async () => {
      try {
        const response = await axios.get(`${server}/investments/get-investments`);
        setInvestments(response.data);

      } catch (error) {

      }
    };

    fetchProducts();
    fetchSuppliers();
    fetchResellers();
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'green';
      case 'pending':
        return 'orange';
      case 'ended':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (

    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <div className='flex justify-between sm:px-10 py-8 items-center'>
          <div>
            <h2 className='font-bold sm:text-xl text-sm'>Welcome back, Admin {admin.username}</h2>
            <p className='text-gray-400 sm:block hidden'>Your current status and analytics are here</p>
          </div>
          <p className='rounded-xl sm:block hidden p-2 border border-orange-500'>{date.toDateString()}</p>
        </div>
        <div className='w-full sm:px-10 px-2 sm:flex'>
          <div className='sm:w-[100%] w-[100%] flex flex-wrap gap-4'>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-xl font-semibold counter-text '>Users</h2>
              <p className='text-2xl mb-3 font-bold'>{users?.length}</p>
              {/* <p className='text-green-400'>5.27%</p> */}
              <a href='/admin/users' className='text-gray-400 underline'>View Users</a>
            </div>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-xl font-semibold counter-text'>Plans</h2>
              <p className='text-2xl mb-3 font-bold'>{plans?.length}</p>
              {/* <p className='text-green-400'>10.23%</p> */}
              <a href='/admin/plans' className='text-gray-400 underline'>View Plans</a>
            </div>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-xl font-semibold counter-text'>User Investments</h2>
              <p className='text-2xl mb-3 font-bold'>{investments?.length}</p>
              {/* <p className='text-green-400'>5.27%</p> */}
              <a href='/admin/investments' className='text-gray-400 underline'>View Investments</a>
            </div>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-xl font-semibold counter-text'>Total Invested Amount</h2>
              <p className='text-2xl mb-3 font-bold'>${totalInvestedAmount.toFixed(2)}</p>
              {/* <p className='text-green-400'>5.27%</p> */}
              {/* <a href='/admin/ads' className='text-gray-400 underline'>Go to Ads</a> */}
            </div>
          </div>
          {/* <div className='sm:w-[60%] sm:block hidden shadow'>
            <SiteVisitsChart />
          </div> */}
        </div>
        <div className='w-full shadow px-10 mt-5 sm:flex hidden gap-4 justify-between'>
          <div className='w-[100%]'>
            <div className='flex justify-between'>
              <h2 className='font-bold sm:text-xl text-sm mb-3'>Recent Investment Requests</h2>
              <a href="/admin/investments" className='text-orange-600 underline sm:flex hidden items-center justify-center gap-1'>
                Go to investments
                <FaArrowCircleRight />
              </a>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                  <tr>
                    <th scope="col" className="p-4">
                      <div className="flex items-center">
                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for="checkbox-all-search" className="sr-only">checkbox</label>
                      </div>
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Coin
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Plan
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Amount
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Actions
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {recentInvestments && recentInvestments.map((investment) => (
                    <tr key={investment._id} className='bg-white border-b hover:bg-gray-50' >
                      <td className='w-4 p-4'>
                        <div className='flex items-center'>
                          <input
                            // id={`checkbox-table-search-${faq.id}`}
                            type='checkbox'
                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                          />
                          <label
                            // for={`checkbox-table-search-${faq.id}`}
                            className='sr-only'
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex'>
                          <p>{investment?.coin}</p>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex'>
                          <p>{plans.find(plan => plan._id === investment.plan)?.name}</p>
                        </div>
                      </td>
                      <td className='px-6 py-4'>
                        <div className='flex'>
                          <p>{investment?.amount}</p>
                        </div>
                      </td>

                      <td className='px-6 py-4'>
                        <div className="flex items-center">
                          <span className={`bg-${getStatusColor(investment.status)}-500 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-${getStatusColor(investment.status)}-400`}>{investment.status}</span>
                        </div>
                      </td>
                      <td className="px-6 py-4">
                        <div className='flex items-center'>
                          <div className=''>
                            <button onClick={() => toggleModal(investment)} className="text-green-500" type="button">Approve</button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
      {isModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure this payment has been made?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleApprove} className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </div>
  )
}

export default AdminDashboard