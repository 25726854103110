import React, { useEffect, useState } from 'react'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import axios from 'axios'
import { server } from '../../server'
// import SupplierReview from '../../components/SupplierReview'

const AdminInvestments = () => {
    const [investments, setInvestments] = useState([]);
    const [plans, setPlans] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(`${server}/investments/approved-investments`);
                setInvestments(response.data);
                const planResponse = await axios.get(`${server}/plans/get-plans`);
                setPlans(planResponse.data);
                const userResponse = await axios.get(`${server}/users/get-users`);
                setUsers(userResponse.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSuppliers();
    }, []);

    const getStatusColor = (status) => {
        switch (status) {
            case 'approved':
                return 'green';
            case 'pending':
                return 'orange';
            case 'ended':
                return 'red';
            default:
                return 'gray';
        }
    };


    return (
        <div className='flex relative'>
            <div className='w-[20%]'>
                <AdminSidebar />
            </div>
            <main className='w-[80%]'>
                <AdminHeadbar />
                <div className='m-[30px] bg-[#f8f9fc]'>
                    <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10'>
                        <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                            <span className='text-2xl font-semibold'>Investments</span>
                        </div>
                    </div>
                    <div className='bg-[#fff] h-[70vh]'>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                                <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-4 py-3">
                                            User
                                        </th>
                                        <th scope="col" className="px-4 py-3">
                                            Coin
                                        </th>
                                        <th scope="col" className="px-4 py-3">
                                            Plan
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Expected Profit
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Start Time
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            End Time
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {investments && investments.map((investment) => (
                                        <tr key={investment._id} className='bg-white border-b hover:bg-gray-50' >
                                            <td className='w-4 p-4'>
                                                <div className='flex items-center'>
                                                    <input
                                                        // id={`checkbox-table-search-${faq.id}`}
                                                        type='checkbox'
                                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                                    />
                                                    <label
                                                        // for={`checkbox-table-search-${faq.id}`}
                                                        className='sr-only'
                                                    >
                                                        checkbox
                                                    </label>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{users.find(user => user._id === investment.user)?.userName}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{investment?.coin}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{plans.find(plan => plan._id === investment.plan)?.name}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{investment?.amount}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{investment?.profit}</p>
                                                </div>
                                            </td>

                                            <td className='px-6 py-4'>
                                                <div className="flex items-center">
                                                    <span className={`bg-${getStatusColor(investment.status)}-500 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-${getStatusColor(investment.status)}-400`}>{investment.status}</span>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex items-center'>
                                                    <p>{investment?.startTime}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex items-center'>
                                                    <p>{investment?.endTime}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default AdminInvestments