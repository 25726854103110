import React, { useEffect, useState } from 'react'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import profile from '../../assets/avatar.jpg'
import axios from 'axios'
import { server } from '../../server'

const Users = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchResellers = async () => { 
            try {
                const response = await axios.get(`${server}/users/get-users`);
                setUsers(response.data);
            } catch (error) {
                console.error(error);
            } 
        };
        
        fetchResellers();
    }, []);
    
  return (
    <div className='flex relative'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
      <AdminHeadbar />
        <div className='m-[30px] bg-[#f8f9fc]'>
        <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10'>
            <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                <span className='text-2xl font-semibold'>Users</span>
            </div>
        </div>
        <div className='bg-[#fff] h-[70vh]'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">         
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                    <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Balance
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {users && users.map((user) => (
                            <tr className="bg-white border-b hover:bg-gray-50" key={user._id}>
                                <td className="w-4 p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                    <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images"/>
                                    <div className="ps-3">
                                        <div className="text-base font-semibold">{user?.userName}</div>
                                        <div className="font-normal text-gray-500">{user?.email}</div>
                                    </div>  
                                </th>
                                <td className="px-6 py-4">
                                    <div className='flex'>
                                        <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{user?.email}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className='flex'>
                                        <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{user?.balance}</span>
                                    </div>
                                </td>    
                            </tr>
                        ))}       
                    </tbody>
                </table>
            </div>
        </div>
        </div>
      </main>
    </div>
  )
}

export default Users