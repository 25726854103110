import React, { useEffect, useState } from 'react'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import axios from 'axios'
import { toast } from 'react-toastify'
import { server } from '../../server'

const PendingInvestments = () => {
    const [investments, setInvestments] = useState([]);
    const [selectedInvestment, setSelectedInvestment] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [plans, setPlans] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchResellers = async () => {
            try {
                const response = await axios.get(`${server}/investments/unapproved-investments`);
                setInvestments(response.data);
                const planResponse = await axios.get(`${server}/plans/get-plans`);
                setPlans(planResponse.data);
                const userResponse = await axios.get(`${server}/users/get-users`);
                setUsers(userResponse.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchResellers();
    }, []);

    const handleApprove = async () => {
        try {
          const response = await axios.patch(`${server}/investments/approve-investment/${selectedInvestment._id}`);
          toast.success(response.data);
          // Refresh the reseller list after approval
          const updatedInvestments = investments.filter(investment => investment._id !== selectedInvestment._id);
          setInvestments(updatedInvestments);
          setIsModalOpen(false)
        } catch (error) {
          toast.error('Failed to approve supplier');
        }
      };
   
    const toggleModal = (investment) => {
        setIsModalOpen(!isModalOpen);
        setSelectedInvestment(investment)
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'approved':
                return 'green';
            case 'pending':
                return 'orange';
            case 'ended':
                return 'red';
            default:
                return 'gray';
        }
    };

    return (
        <div className='flex'>
            <div className='w-[20%]'>
                <AdminSidebar />
            </div>
            <main className='w-[80%]'>
                <AdminHeadbar />
                <div className='m-[30px] bg-[#f8f9fc]'>
                    <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10'>
                        <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                            <span className='text-2xl font-semibold'>Pending Investments</span>
                        </div>
                    </div>
                    <div className='bg-[#fff] h-[70vh]'>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                                <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-4 py-3">
                                            User
                                        </th>
                                        <th scope="col" className="px-4 py-3">
                                            Coin
                                        </th>
                                        <th scope="col" className="px-4 py-3">
                                            Plan
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Amount
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Actions
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {investments && investments.map((investment) => (
                                        <tr key={investment._id} className='bg-white border-b hover:bg-gray-50' >
                                            <td className='w-4 p-4'>
                                                <div className='flex items-center'>
                                                    <input
                                                        // id={`checkbox-table-search-${faq.id}`}
                                                        type='checkbox'
                                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                                    />
                                                    <label
                                                        // for={`checkbox-table-search-${faq.id}`}
                                                        className='sr-only'
                                                    >
                                                        checkbox
                                                    </label>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{users.find(user => user._id === investment.user)?.userName}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{investment?.coin}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{plans.find(plan => plan._id === investment.plan)?.name}</p>
                                                </div>
                                            </td>
                                            <td className='px-6 py-4'>
                                                <div className='flex'>
                                                    <p>{investment?.amount}</p>
                                                </div>
                                            </td>

                                            <td className='px-6 py-4'>
                                                <div className="flex items-center">
                                                    <span className={`bg-${getStatusColor(investment.status)}-500 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-${getStatusColor(investment.status)}-400`}>{investment.status}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className='flex items-center'>
                                                    <div className=''>
                                                        <button onClick={() => toggleModal(investment)} className="text-green-500" type="button">Approve</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            {isModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to approve this investment?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleApprove} className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
           
        </div>
    );
}

export default PendingInvestments;
