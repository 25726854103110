import React, { useEffect, useState } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar"; 
import { FaTachometerAlt, FaUsers } from "react-icons/fa";
import { RiUserStarLine } from "react-icons/ri";
import { CiShoppingCart } from "react-icons/ci";
// import { RiCustomerService2Line } from "react-icons/ri";
import { IoMdClose, IoMdLogOut } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdMenu } from "react-icons/io";
import logo from '../../../assets/GooglePlayStore.png'
import { useAuth } from '../../../middleware/AuthContext';
import { toast } from 'react-toastify';

const AdminSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 979) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLogout = () => {
    setAuth({
      ...auth,
      admin: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate('/admin/login')
  };

  return (
    <div id="app" style={({ height: "100vh" }, { display: "flex" })} className='fixed'>
      <Sidebar  
          collapsed={collapsed}
            
         backgroundColor='white' 
         className='h-screen relative ' >
          <button className="sb-button absolute top-0 right-2" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <IoMdClose size={28} /> : <IoMdMenu size={28} />}
          </button>
           
            <div className='flex gap-3 py-5 items-center'>
                {/* <img src={logo} alt="" width={70} height={100} /> */}
                <h2 className=' text-orange-500 font-semibold text-2xl w-full text-center'>Admin Panel</h2>
            </div>
            <Menu>
            <MenuItem component={<Link to="/admin" />} icon={<FaTachometerAlt />}> Dashboard </MenuItem>
            <div style={{ padding: '0 15px', }}>
              <span
                className='font-bold text-sm text-gray-400'
              >
                User Management
              </span>
            </div>
                <SubMenu icon={<FaUsers />} label="Users">
                <MenuItem component={<Link to="/admin/users" />}> All Users </MenuItem>
                </SubMenu>
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Investments Management
                    </span>
                </div>
                <SubMenu icon={<FaUsers />} label="Investments">
                <MenuItem component={<Link to="/admin/investments" />}> All Investments </MenuItem>
                {/* <MenuItem component={<Link to="/admin/add-supplier" />} > Add new supplier </MenuItem> */}
                <MenuItem component={<Link to="/admin/pending-investments" />}> Pending Investments </MenuItem>
                </SubMenu>
                {/* <MenuItem icon={<RiUserStarLine />} style={{marginBottom: "20px"}}>My shops</MenuItem>   */}
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Plans Management
                    </span>
                </div>
                <SubMenu icon={<FaUsers />} label="Plans">
                <MenuItem component={<Link to="/admin/plans" />}> All Plans </MenuItem>
                <MenuItem component={<Link to="/admin/add-plan" />}> Add new plan </MenuItem>
                {/* <MenuItem component={<Link to="/admin/add-bulk-products" />}> Add bulk products </MenuItem> */}
                </SubMenu>
                {/* <MenuItem component={<Link to="/admin/account" />} icon={<FaRegUserCircle />}> My Account </MenuItem> */}
                <MenuItem onClick={handleLogout} icon={<IoMdLogOut />}> Logout </MenuItem>
            </Menu>
        </Sidebar>
    </div>
    
  )
}

export default AdminSidebar