import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logo from "../assets/images/logo.png"
import { useAuth } from '../middleware/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);
  const [auth, setAuth] = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate('/login')
  };


  return (
    <div className='bg-[#17181c] flex justify-between items-center h-20 px-4 text-white'>
      <a href="/" className='flex items-center justify-center'>
        <img src={logo} width="32" height="32" alt="Cryptex logo" />
        <span className='w-full text-3xl font-bold text-white'>CryptoWell</span>
      </a>
      {/* Desktop Navigation */}
      <ul className='hidden md:flex md:items-center'>
        
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="/">Home</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#about">About</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#plans">Plans</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#faq">FAQ</a>
        </li>
        {!auth?.user ? (
          <div className='flex items-center justify-center gap-4'>
            <li>
              <a href="/signup" className='p-2 border rounded-md hover:bg-[#090917] duration-300 hover:text-white cursor-pointer border-gray-600'>Sign Up</a>
            </li>
            <li>
              <a href="/login" className='p-2 bg-[#5a6bea] rounded-md hover:bg-[#090917] duration-300 hover:text-white cursor-pointer border-gray-600'>Login</a>
            </li>
          </div>
        ) : (
          <>
            <button onClick={toggleDropdown} type="button" class="flex text-sm w-8 h-8 items-center bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
              <span class="sr-only">Open user menu</span>
              <img class="w-8 h-8 rounded-full" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="user phot" />
            </button>
            {isDropdownOpen && (
              <div class="absolute right-3 top-[100px] z-50 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg" id="dropdown">
                <div class="py-3 px-4">
                  <span class="block text-sm font-semibold text-gray-900">{auth?.user.userName}</span>
                  <span class="block text-sm text-gray-500 truncate dark:text-gray-400">{auth?.user?.email}</span>
                </div>
                <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
                  <li>
                    <a href='/profile' class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">My profile</a>
                  </li>

                </ul>
                <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
                  <li>
                    <a href="/invest" class="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                      My Investments
                    </a>
                  </li>

                </ul>
                <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
                  <li>
                    <button onClick={handleLogout} class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</button>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500'
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
        }
      >
        {/* Mobile Logo */}
        <a href="/" className='flex items-center justify-center m-4'>
          <img src={logo} width="32" height="32" alt="Cryptex logo" />
          <span className='w-full text-3xl font-bold text-[#5a6bea]'>CryptoWell</span>
        </a>

        {/* Mobile Navigation Items */}
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="/">Home</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#about">About</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#plans">Plans</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li className='p-4 hover:bg-[#5a6bea] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
          <a href="#faq">FAQ</a>
        </li>
        {!auth?.user ? (
          <div className='flex mt-3 justify-between items-center'>
            <li>
              <a href="/signup" className='p-4 border-b rounded-xl hover:bg-[#090917] duration-300 hover:text-black cursor-pointer border-gray-600'>Sign Up</a>
            </li>
            <li>
              <a href="/login" className='p-4 border-b rounded-xl hover:bg-[#090917] duration-300 hover:text-black cursor-pointer border-gray-600'>Login</a>
            </li>
          </div>
        ) : (
          <>
            <button onClick={toggleDropdown} type="button" class="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="dropdown">
              <span class="sr-only">Open user menu</span>
              <img class="w-8 h-8 rounded-full" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="user phot" />
            </button>
            {isDropdownOpen && (
              <div class="absolute right-3 top-[100px] z-50 my-4 max-w-sm text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg" id="dropdown">
                <div class="py-3 px-4">
                  <span class="block text-sm font-semibold text-gray-900">{auth?.user.userName}</span>
                  <span class="block text-sm text-gray-500 truncate dark:text-gray-400">{auth?.user?.email}</span>
                </div>
                <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
                  <li>
                    <a href='/profile' class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white">My profile</a>
                  </li>

                </ul>
                <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
                  <li>
                    <a href="/invest" class="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                      {/* <svg class="mr-2 w-4 h-4 text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18"><path d="M17.947 2.053a5.209 5.209 0 0 0-3.793-1.53A6.414 6.414 0 0 0 10 2.311 6.482 6.482 0 0 0 5.824.5a5.2 5.2 0 0 0-3.8 1.521c-1.915 1.916-2.315 5.392.625 8.333l7 7a.5.5 0 0 0 .708 0l7-7a6.6 6.6 0 0 0 2.123-4.508 5.179 5.179 0 0 0-1.533-3.793Z"/></svg> */}
                      My Investments
                    </a>
                  </li>

                </ul>
                <ul class="py-1 text-gray-500 dark:text-gray-400" aria-labelledby="dropdown">
                  <li>
                    <button onClick={handleLogout} class="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</button>
                  </li>
                </ul>
              </div>
            )}
          </>
        )}


      </ul>
    </div>
  );
};

export default Navbar;