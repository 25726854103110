import React, { useState } from 'react'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'

const AddPlan = () => {
    const [formData, setFormData] = useState({
        name: '',
        price: '',
        lastPrice: '',
        profitPercentage: '',
        coin: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${server}/plans/create-plan`, formData);
            // Optionally, you can reset the form after successful submission
            setFormData({
                name: '',
                price: '',
                lastPrice: '',
                profitPercentage: '',
                coin: '',
            });
            toast.success('Plan created successfully!');
        } catch (error) {
            toast.error('Error creating plan. Please try again.');
        }
    };
    return (
        <div className='flex'>
            <div className='w-[20%]'>
                <AdminSidebar />
            </div>
            <main className='w-[80%]'>
                <AdminHeadbar />
                <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}>
                    <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                        <span className='font-medium text-xl'>Create New Plan</span>
                    </div>
                    <div className='flex p-[30px] border-b-2 border-dashed'>
                        <div className='basis-[30%]'>
                            <h2 className='font-semibold mb-4'>Information</h2>
                            <p className='text-gray-600'>Add plan information and create a new plan from here</p>
                        </div>
                        <div className='basis-[70%] bg-white p-10'>
                            <div className=" mx-auto">
                                <div className="mb-5">
                                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Plan Name</label>
                                    <input type="text" id="name" name='name' value={formData.name} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                </div>
                                <div className="mb-5">
                                    <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Start Price(USD)</label>
                                    <input type="number" id="price" name='price' value={formData.price} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                                </div>
                                <div className="mb-5">
                                    <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Last Price(USD)</label>
                                    <input type="number" id="price" name='lastPrice' value={formData.lastPrice} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                                </div>
                                <div className="mb-5">
                                    <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Profit Percentage</label>
                                    <input type="number" id="duration" name='profitPercentage' value={formData.profitPercentage} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                </div>
                                <div className="mb-5">
                                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Coin</label>
                                    <select name="coin" id="" value={formData.coin} onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                        <option value='' disabled>Coin</option>
                                        <option value='BTC'>Bitcoin</option>
                                        <option value='ETH'>Ethereum</option>
                                        <option value='UST'>UST</option>
                                        
                                    </select>
                                </div>
                                
                            </div>
                        </div>
                    </div>
        
                    <div className='w-full flex justify-between items-center'>
                        <a href='/admin/plans' className='flex items-center gap-1 text-orange-600'>
                            <FaArrowAltCircleRight />
                            Go to plans
                        </a>
                        <div className='flex gap-2'>
                            <a href='/admin/plans' class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</a>
                            <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Create Plan</button>
                        </div>
                    </div>
                </form>
            </main>
        </div>
    )
}

export default AddPlan