import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import heroImg from '../assets/images/hero-banner.png'
import bitcoin from '../assets/images/bitcoin.png'
import { FaArrowRight } from "react-icons/fa";
import inst1 from '../assets/images/instruction-1.png'
import inst2 from '../assets/images/instruction-2.png'
import inst3 from '../assets/images/instruction-3.png'
import about from '../assets/images/about-banner.png'
import { FaCheckCircle } from "react-icons/fa";
import Footer from '../components/Footer'
import { server } from '../server'
import axios from 'axios';
import tether from '../assets/images/tether.png';
import bnb from '../assets/images/bnb.png';
import ethereum  from '../assets/images/Ethereum.png';


const Home = () => {
  const [plans, setPlans] = useState([]);
  const [showMore, setShowMore] = useState(false);

    const handleShowMore = (e) => {
        e.preventDefault();
        setShowMore(!showMore);
    };

  useEffect(() => {
    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(`${server}/plans/get-plans`);
            setPlans(response.data);
        } catch (error) {

        }
    };
    fetchSuppliers();
}, []);
  return (
    <>
      <Navbar />
      <div className='sm:flex justify-between sm:h-[85vh] w-full items-center bg-[#17181c] px-4 py-2'>
        <div className='sm:w-[50%] w-full'>
          <h1 className='sm:text-6xl text-3xl sm:text-start text-center mb-4 font-bold text-white'>CryptoWell Investment
            Now available to everyone.</h1>
          <p className='sm:text-xl text-lg sm:text-start text-center text-gray-500'>At CryptoWell Investment, we see investing differently.
            Today’s financial system is complex, exclusive and expensive - making it hard for the typical person to start investing.
            So, we’re building a platform without digital walls and complex barriers. </p>
          <p className='sm:text-xl text-lg sm:text-start text-center text-gray-500 mb-6'> A platform that empowers you to see new possibilities for your money - and helps make them a reality.</p>
          <a href='/signup' className='text-white rounded-lg bg-[#5a6bea] sm:w-[150px] sm:block hidden px-7 py-4'>Get Started</a>
        </div>
        <div className='sm:w-[45%] hidden sm:block'>
          <img src={heroImg} alt="" className='animated object-cover' />
        </div>
      </div>
      <div className='bg-[#17181c] px-4'>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
          <h1 className='text-4xl text-white text-center font-semibold'>MarketPlace</h1>
          <hr className='w-[10%]' />
          <p className='text-xl text-gray-500 text-center'>See Our Marketplace</p>
        </div>
        <div className='sm:flex block items-center'>
          {/* <div className='sm:w-[60%] w-full'>
            <img src={coins} alt="" />
          </div> */}
          <div className='sm:w-[100%] w-full'>
            <div className='bg-[#17181c] shadow-md rounded-lg p-4'>
              <div className='mb-4'>
                <h1 className='text-white text-xl font-bold'>Coins</h1>
                <p className='text-gray-500 text-lg'>Here you can find more details about the available coins.</p>
              </div>
              <div className='flex rounded-md bg-white items-center w-full'>
                <img src={bitcoin} className='w-[100px] h-full object-contain' alt="" />
                <div className='border-l-2 p-4 w-full'>
                  <p className='text-xl flex items-center text-black'>
                    <img src={bitcoin} alt="" className='w-[30px] h-[30px]' />
                    Bitcoin
                  </p>
                  <h1 className='text-2xl font-bold'>USD 70,167.30</h1>
                  <div className='flex items-center justify-between'>
                    <p className='text-gray-500'>1,382,598,711,867</p>
                    <p className='p-2 bg-green-500 rounded-xl'>+0.61%</p>
                  </div>
                </div>
              </div>
              {showMore && (
                    <div>
                        <div className='flex bitcoin rounded-md bg-white items-center w-full mt-4'>
                            <img src={ethereum} className='w-[100px] h-full object-contain' alt="Bitcoin" />
                            <div className='border-l-2 p-4 w-full'>
                                <p className='text-xl flex items-center text-black'>
                                    <img src={ethereum} alt="Bitcoin" className='w-[30px] h-[30px]' />
                                    Ethereum
                                </p>
                                <h1 className='text-2xl font-bold'>USD 3,939.51</h1>
                                <div className='flex items-center justify-between'>
                                    <p className='text-gray-500'>473,279,895,426</p>
                                    <p className='p-2 bg-red-500 rounded-xl'>-0.21%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex bitcoin rounded-md bg-white items-center w-full mt-4'>
                            <img src={tether} className='w-[100px] h-full object-contain' alt="Bitcoin" />
                            <div className='border-l-2 p-4 w-full'>
                                <p className='text-xl flex items-center text-black'>
                                    <img src={tether} alt="Bitcoin" className='w-[30px] h-[30px]' />
                                    Tether USDT
                                </p>
                                <h1 className='text-2xl font-bold'>USD 0.999</h1>
                                <div className='flex items-center justify-between'>
                                    <p className='text-gray-500'>111,832,518,181</p>
                                    <p className='p-2 bg-green-500 rounded-xl'>+0.1%</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex bitcoin rounded-md bg-white items-center w-full mt-4'>
                            <img src={bnb} className='w-[100px] h-full object-contain' alt="Bitcoin" />
                            <div className='border-l-2 p-4 w-full'>
                                <p className='text-xl flex items-center text-black'>
                                    <img src={bnb} alt="Bitcoin" className='w-[30px] h-[30px]' />
                                    BNB
                                </p>
                                <h1 className='text-2xl font-bold'>USD 610.73</h1>
                                <div className='flex items-center justify-between'>
                                    <p className='text-gray-500'>90,122,265,051</p>
                                    <p className='p-2 bg-green-500 rounded-xl'>+1.3%</p>
                                </div>
                            </div>
                        </div>
                        {/* Add more divs here as needed */}
                    </div>
                )}
              <button onClick={handleShowMore} className='flex text-white underline items-center gap-1'>{showMore ? "See Less" : "See More"} <FaArrowRight /></button>
              
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#17181c] px-4 py-4'>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
          <h1 className='text-4xl text-white text-center font-semibold'>How It Works</h1>
          <hr className='w-[10%]' />
          <p className='text-xl text-gray-500 text-center'>Our platform provides a user-friendly interface for exploring a diverse range of cryptocurrencies,
            backed by comprehensive market analyses and real-time data.</p>
        </div>
        <section class=" overflow-hidden bg-[#17181c]">
          <div class="mt-2 md:mt-0 py-12 pb-6 sm:py-16 lg:pb-24 overflow-hidden">
            <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative">
              <div class=" mt-12 lg:mt-20">
                <div class="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                  <svg class="w-full" xmlns="http://www.w3.org/2000/svg" width="875" height="48" viewBox="0 0 875 48"
                    fill="none">
                    <path
                      d="M2 29C20.2154 33.6961 38.9915 35.1324 57.6111 37.5555C80.2065 40.496 102.791 43.3231 125.556 44.5555C163.184 46.5927 201.26 45 238.944 45C312.75 45 385.368 30.7371 458.278 20.6666C495.231 15.5627 532.399 11.6429 569.278 6.11109C589.515 3.07551 609.767 2.09927 630.222 1.99998C655.606 1.87676 681.208 1.11809 706.556 2.44442C739.552 4.17096 772.539 6.75565 805.222 11.5C828 14.8064 850.34 20.2233 873 24"
                      stroke="#D4D4D8" stroke-width="3" stroke-linecap="round" stroke-dasharray="1 12" />
                  </svg>
                </div>
                <div
                  class=" grid grid-cols-1 text-center gap-y-8 sm:gap-y-10 md:gap-y-12 md:grid-cols-3 gap-x-12">
                  <div>
                    <div
                      class="flex items-center justify-center w-16 h-16 mx-auto bg-white dark:bg-gray-800 border-2 border-gray-200 dark:border-gray-700 rounded-full shadow">
                      <span class="text-xl font-semibold text-gray-700 dark:text-gray-200">
                        <img src={inst1} alt="" />
                      </span>
                    </div>
                    <h3
                      class="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-white md:mt-10">
                      Register/Login
                    </h3>
                    <p class="mt-3 sm:mt-4 text-base text-gray-600 dark:text-gray-400">
                      Create an account with us and provide all the required details.
                      Sign In to access your dashboard by clicking on the profile icon at the top right of the website.
                    </p>
                  </div>
                  <div>
                    <div
                      class="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 dark:border-gray-700 rounded-full shadow">
                      <img src={inst3} alt="" />
                    </div>
                    <h3
                      class="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-white md:mt-10">
                      Deposit Coins
                    </h3>
                    <p class="mt-3 sm:mt-4 text-base text-gray-600 dark:text-gray-400">
                      Start investing by depositing coins to your account and watch as your crypto investment grows.
                    </p>
                  </div>
                  <div>
                    <div
                      class="flex items-center justify-center w-16 h-16 mx-auto bg-white dark:bg-gray-800 border-2 border-gray-200 dark:border-gray-700 rounded-full shadow">
                      <img src={inst2} alt="" />
                    </div>
                    <h3
                      class="mt-4 sm:mt-6 text-xl font-semibold leading-tight text-white md:mt-10">
                      Withdraw Your Profit
                    </h3>
                    <p class="mt-3 sm:mt-4 text-base text-gray-600 dark:text-gray-400">
                      Submit a request to withdraw your profit to your wallet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section id='plans' class="bg-[#17181c]">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <div class="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 class="mb-4 text-3xl font-extrabold tracking-tight text-white">Investment Plans</h2>
            <p class="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">Choose the plan of your choice and invest.</p>
          </div>
          <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">

          {plans && plans.map(plan => (
            <div
            class="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
            <h3 class="mb-4 text-2xl font-semibold">{plan?.name}</h3>
            <p class="font-light text-gray-500 sm:text-lg dark:text-gray-400">Invest in your future.</p>
            <div class="flex items-baseline justify-center my-8">
              <div>
              <span class="mr-2 text-2xl font-extrabold">${plan?.price}</span>
                <span> - </span>
              <span class="mr-2 text-2xl font-extrabold">${plan?.lastPrice}</span>
              </div>
              <span class="text-gray-500 dark:text-gray-400"></span>
            </div>

            <ul class="mb-8 space-y-4 text-left">
              <li class="flex items-center space-x-3">

                <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"></path>
                </svg>
                <span>Coin: {plan?.coin}</span>
              </li>
              <li class="flex items-center space-x-3">

                <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                  viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"></path>
                </svg>
                <span>Percentage profit: <span class="font-semibold">{plan?.profitPercentage}%</span></span>
              </li>
            </ul>
            <a href="/invest"
              class="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">Get
              started</a>
          </div>
          ))}

            
          </div>
        </div>
      </section>
      <section class="bg-[#17181c]">
        <div class="px-4">
          <div class="flex flex-col items-center">
            <div class="text-center">
              <p class="text-lg font-medium text-white font-pj">Thousands of people have said how good we are</p>
              <h2 class="mt-4 text-3xl font-bold text-white sm:text-4xl xl:text-5xl font-pj">Our happy clients say about us</h2>
            </div>
            <div class="mt-8 text-center md:mt-16 md:order-3">
              <a href="/" title="" class="pb-2 text-base font-bold leading-7 text-white transition-all duration-200 border-b-2 border-gray-900 hover:border-gray-600 font-pj focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 hover:text-gray-600"> Check all 2,157 reviews </a>
            </div>
            <div class="mt-10 md:mt-24 md:order-2">
              <div class="-inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                <div class="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                //  style="background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)"
                ></div>
              </div>

              <div id="testimonials" class="grid grid-cols-1 gap-4 mx-auto md:max-w-none lg:gap-10 sm:grid-cols-3">
                <div class="flex flex-col overflow-hidden shadow-xl">
                  <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                      </div>

                      <blockquote class="flex-1 mt-8">
                        <p class="text-lg leading-relaxed text-gray-900 font-pj">“Shoutout to @officialibrahim for being my go-to crypto investment platform!
                          The user-friendly
                          interface, diverse range of trading strategies, and seamless automation definitely stands out.
                          Thanks for helping me level up"</p>
                      </blockquote>
                    </div>

                    <div class="flex items-center mt-8">
                      <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="" />
                      <div class="ml-4">
                        <p class="text-base font-bold text-gray-900 font-pj">Leslie Alexander</p>
                        <p class="mt-0.5 text-sm font-pj text-gray-600">Client</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col overflow-hidden shadow-xl">
                  <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                      </div>

                      <blockquote class="flex-1 mt-8">
                        <p class="text-lg leading-relaxed text-gray-900 font-pj">“@officialcryptowellinvestment
                          offers
                          such amazing crypto
                          investments options.
                          Made my first crypto experience so easy, quick and seamless. Definitely a
                          must try!”</p>
                      </blockquote>
                    </div>

                    <div class="flex items-center mt-8">
                      <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="" />
                      <div class="ml-4">
                        <p class="text-base font-bold text-gray-900 font-pj">Jacob Jones</p>
                        <p class="mt-0.5 text-sm font-pj text-gray-600">Client</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col overflow-hidden shadow-xl">
                  <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                      </div>

                      <blockquote class="flex-1 mt-8">
                        <p class="text-lg leading-relaxed text-gray-900 font-pj">“@officalcryptoinvestment
                          Website that is using ormai gives 6 months, offers interest at 10%
                          your USDT without any block and always stored and available, the strong point is the possibility
                          crypto. Assistance
                          PERFETTA, experience
                          very positive.”</p>
                      </blockquote>
                    </div>

                    <div class="flex items-center mt-8">
                      <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="" />
                      <div class="ml-4">
                        <p class="text-base font-bold text-gray-900 font-pj">Jenny Wilson</p>
                        <p class="mt-0.5 text-sm font-pj text-gray-600">Client</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col overflow-hidden shadow-xl">
                  <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                      </div>

                      <blockquote class="flex-1 mt-8">
                        <p class="text-lg leading-relaxed text-gray-900 font-pj">“Just made my first crypto investment through @officailcryptowellinvestmen and the process could not have been smoother! Brilliant product, recommend it 10/10!”</p>
                      </blockquote>
                    </div>

                    <div class="flex items-center mt-8">
                      <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="" />
                      <div class="ml-4">
                        <p class="text-base font-bold text-gray-900 font-pj">Jacob Njoroge</p>
                        <p class="mt-0.5 text-sm font-pj text-gray-600">Client</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col overflow-hidden shadow-xl">
                  <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                      </div>

                      <blockquote class="flex-1 mt-8">
                        <p class="text-lg leading-relaxed text-gray-900 font-pj">“Theme-based investing in crypto is a game-changer!!
                          @officailcryptowellinvestment is killing it!”</p>
                      </blockquote>
                    </div>

                    <div class="flex items-center mt-8">
                      <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="" />
                      <div class="ml-4">
                        <p class="text-base font-bold text-gray-900 font-pj">James Sinclair</p>
                        <p class="mt-0.5 text-sm font-pj text-gray-600">Client</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col overflow-hidden shadow-xl">
                  <div class="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                        <svg class="w-5 h-5 text-[#FDB241]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                          />
                        </svg>
                      </div>

                      <blockquote class="flex-1 mt-8">
                        <p class="text-lg leading-relaxed text-gray-900 font-pj">“I am overwhelmed by the immense profit I have been making from this platform!! would 100% recommend to any interested person.”</p>
                      </blockquote>
                    </div>

                    <div class="flex items-center mt-8">
                      <img class="flex-shrink-0 object-cover rounded-full w-11 h-11" src="https://i.ibb.co/4pDNDk1/avatar.png" alt="" />
                      <div class="ml-4">
                        <p class="text-base font-bold text-gray-900 font-pj">Mike Jones</p>
                        <p class="mt-0.5 text-sm font-pj text-gray-600">Client</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id='about' className='sm:flex items-center bg-[#17181c] px-4 py-4'>
        <div className='w-[50%] hidden sm:block'>
          <img src={about} alt="" />
        </div>
        <div className='sm:w-[50%]'>
          <h1 className='sm:text-5xl text-3xl font-bold text-white mb-3'>What Is Crypto Well Investment</h1>
          <p className='text-gray-500 mb-5'>CryptoWell Investment was founded in 2020 in Vienna, Austria with the aim of making investing accessible for everyone.
            Our goals since day one was to reimagine what it means to invest, by making simple,
            easy-to-use financial products for everyone.</p>
          <h1 className='text-white text-2xl font-bold flex gap-2 items-center mb-2'><FaCheckCircle color='blue' />  View real-time cryptocurrency prices</h1>
          <p className='text-gray-500 mb-4'>Experience a variety of trading on CryptoWell You can use various types of Coins transactions such as Spot Trade, Futures Trade, P2P, Staking, Mining, and margin.</p>
          <h1 className='text-white text-2xl font-bold flex gap-2 items-center mb-2'> <FaCheckCircle color='blue' /> Buy and sell BTC, ETH, XRP, OKB, Etc...</h1>
          <p className='text-gray-500 mb-4'>Experience a variety of trading on CryptoWell You can use various types of Coins transactions such as Spot Trade, Futures Trade, P2P, Staking, Mining, and margin.</p>
          <a href="/" className='bg-[#5a6bea] text-white p-2 rounded-md'>Get Started</a>
        </div>
      </div>
      <section id='faq' class="py-10 bg-[#17181c] sm:py-16 lg:py-24">
        <div class="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
          <h2 class="text-3xl font-bold leading-tight text-white sm:text-4xl lg:text-5xl">Frequently Asked Questions</h2>

          <div class="flow-root mt-12 sm:mt-16">
            <div class="divide-y divide-gray--200 -my-9">
              <div class="py-9">
                <p class="text-xl font-semibold text-white">How long does it take to transfer ether?</p>
                <p class="mt-3 text-base text-gray-600">Ethereum confirmation times vary widely depending upon the amount of gas one is willing to spend, along with other market factors. </p>
                {/* <p class="mt-3 text-base text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
              </div>
              <div class="py-9">
                <p class="text-xl font-semibold text-white">How much profit can I make in a day?</p>
                <p class="mt-3 text-base text-gray-600">This depends on the plan you invest in. The hight the premium the more the profit.</p>
              </div>
              <div class="py-9">
                <p class="text-xl font-semibold text-white">What is a wallet address?</p>
                <p class="mt-3 text-base text-gray-600">All cryptocurrencies require addresses, or public identifiers, to send and receive funds.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Home